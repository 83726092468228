import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {ref,watch} from "vue";

export default /*@__PURE__*/_defineComponent({
  ...{
	name: "CountDown",
	inheritAttrs:false
},
  __name: 'CountDown',
  props: ['TimerFlag'],
  emits: ['resetFlag'],
  setup(__props, { emit: __emit }) {


let time = ref(60)
let props = __props
let $emit = __emit
watch(
		()=>props.TimerFlag,
		()=>{
			let timmer =	setInterval(()=>{
				time.value --;
				if (time.value == 0) {
					clearInterval(timmer);
					$emit('resetFlag',false)
				}

			},1000)
		},
		{
			//当监听到到值的时候就触发
			immediate:true,
		}
)
//倒计时为0 时通知父组件清空状态

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("span", null, "获取验证码" + _toDisplayString(_unref(time)) + "s", 1)
  ]))
}
}

})