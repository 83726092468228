import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "login_container" }
const _hoisted_2 = { class: "login" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "botton" }
const _hoisted_10 = { class: "login" }
const _hoisted_11 = { class: "botton" }
const _hoisted_12 = { class: "webchar" }
const _hoisted_13 = { class: "login" }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { class: "botton" }
const _hoisted_16 = { class: "webchar" }
const _hoisted_17 = { class: "login" }
const _hoisted_18 = { class: "webchar" }

import {Lock, User} from '@element-plus/icons-vue'
import userUserStore from "@/stores/user";
import {computed, onMounted, reactive, ref} from "vue";
import CountDown from "@/components/CountDown/CountDown.vue";
import {ElMessage} from "element-plus";
import {registerreq, loginByCount} from "@/axiosAPI/device/device.js";
import type {FormInstance, FormRules,} from 'element-plus'
import {useRouter, useRoute} from "vue-router";


import {Plus} from '@element-plus/icons-vue'

import type {UploadProps} from 'element-plus'

//用户头像上传


export default /*@__PURE__*/_defineComponent({
  ...{
	name: "login",
	inheritAttrs: false,

},
  __name: 'login_dialog',
  setup(__props) {


const ruleFormregisterRef = ref<FormInstance>()
const ruleFormRefloginBypassword = ref<FormInstance>()
const ruleFormRefloginByPhone = ref<FormInstance>()

//获取仓库数据（visiable） 可以控制login组件对话框的显示和隐藏
let userStore = userUserStore();
let TimerFlag = ref<boolean>(false);
let $router = useRouter();
//点击登录后检查路由上有没有参数，没有的话往首页跳
let $route = useRoute();

//登录方式控制变量
let wx_scane = ref(0) //0 手机号登录， 1： 微信扫码登录
//切换扫码登录按钮
const changeScaner = () => {
	wx_scane.value = 1;
}
//收集登录表单数据
let loginParamBypassword = reactive({
	name: '',
	password: '',
})
let loginParamByphone = reactive({
	phone: '',
	phoneCode: '',
})
//收集注册参数

let userParam = reactive({
			name: '',
			nick_name: '',
			password: '',
			repassword: '',
			gender: 0,
			avatar: '',
			phone: '',
			email: '',
			token: '',
			status: 0,
			delete: 0,
			remark: '',
			creator: '',
			updater: '',
			create_time: '',
			updata_time: '',
		}
)

let companyParam = reactive({
	companyName: '',
	password: '',
	repassword: '',
	businessEntity: '',
	industry: '',
	creditIndentification: '',
	logo: '',
	strength: '',
	starLevel: '',
	businessLicenseNumber: '',
	deviceModel: '',
	address: '',
	establishmentData: '',
	registerCapital: '',
	mainBusiness: '',
	entityIdCardImage1: '',
	entityIdCardImage2: '',
	entityCammerImage: '',
	licenceImage: ''

})

const validatorRepassword = (rule,value,callback) =>{
	let istrue = computed(() => {
		const reg = /^(?=.*\d)(?=.*[A-z])[\da-zA-Z]{1,16}$/;
		return reg.test(value);
	})
	if (!value) {
		callback(new Error("请输入密码"))
	} else if (!istrue) {
		callback(new Error("字母数字组合的1-16个字符"))
	}else if (value !== userParam.password){
		callback(new Error("两次密码输入不一致"))
	}
	else {
		if (value && istrue && value === userParam.password) {
			callback()
		}
	}
}
const validatorRepasswordc = (rule,value,callback) =>{
	let istrue = computed(() => {
		const reg =  /^(?=.*\d)(?=.*[A-z])[\da-zA-Z]{1,16}$/;
		return reg.test(value);
	})
	if (!value) {
		callback(new Error("请输入密码"))
	} else if (!istrue) {
		callback(new Error("字母数字组合的1-16个字符"))
	}else if (value !== companyParam.password){
		callback(new Error("两次密码输入不一致"))
	}
	else {
		if (value && istrue && value === companyParam.password) {
			callback()
		}
	}
}
const validatorNameU = (rule, value,callback)=>{
	const patten = /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/
	const limit = /^(?!_)(?![0-9])/
	if (!patten.test(value)) return callback(new Error('只能包含中文、英文字母、数字和下划线'))
	if (!limit.test(value)) return callback(new Error('不能以数字或者下划线开头'))
	callback()
}
const validatorNameC = (rule, value,callback)=>{
	const patten = /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/
	const limit = /^(?!_)(?![0-9])/
	if (!patten.test(value)) return callback(new Error('只能包含中文、英文字母、数字和下划线'))
	if (!limit.test(value)) return callback(new Error('不能以数字或者下划线开头'))
	callback()
}



//用户注册校验
const userRules = reactive<FormRules<typeof userParam>>({
	name: [{validator:validatorNameU,trigger:'blur'}],
	nick_name: [
		{required: true, message: '请输入用户昵称', trigger: 'change',},
		{min: 2, max: 10, message: '字符保持在在2-10之间', trigger: 'blur'},
	],
	password: [
			{required:true,message:"请输入密码",trigger:"blur"},
			// {validator: validatePassword, trigger: 'blur'},
		{pattern:/^(?=.*\d)(?=.*[A-z])[\da-zA-Z]{1,9}$/,message:"密码格式不正确",trigger:"blur"}
	],
	repassword: [{validator: validatorRepassword, trigger: 'blur',}],
	gender: [{required: true, message: '请选择性别', trigger: 'change',},],
	avatar: [{required: true, message: '请上传头像', trigger: 'change',},],
	phone: [{required: true, message: '请输入电话号码', trigger: 'change',},],
	email: [{required: true, message: '请输入邮箱', trigger: 'change',},],
})

const companyRules = reactive<FormRules<typeof companyParam>>({
	companyName: [{validator:validatorNameC,trigger:'blur'}],
	password: [
		{required:true,message:"请输入密码",trigger:"blur"},
		// {validator: validatePassword, trigger: 'blur'},
		{pattern:/^(?=.*\d)(?=.*[A-z])[\da-zA-Z]{1,16}$/,message:"字母数字组合的1-16个字符",trigger:"blur"}
	],
	repassword: [
			{validator: validatorRepasswordc, trigger: "change"}
	],
	businessEntity: [{required: true, message: "请输入法人姓名", trigger: "change"}],
	industry: [{required: true, message: "请输入所属行业", trigger: "change"}],
	logo: [{required: true, message: "请上传公司logo", trigger: "change"}],
	businessLicenseNumber: [{required: true, message: "请输入营业执照号码", trigger: "change"}],
	establishmentData: [{required: true, message: "请选择成立日期", trigger: "change"}],
	registerCapital: [{required: true, message: "请输入注册资本", trigger: "change"}],
	mainBusiness: [{required: true, message: "请输入主营业务", trigger: "change"}],
	entityIdCardImage1: [{required: true, message: "请上传身份证正面照", trigger: "change"}],
	entityIdCardImage2: [{required: true, message: "请上传身份证反面照", trigger: "change"}],
	entityCammerImage: [{required: true, message: "请上传法人持证照片", trigger: "change"}],
	licenceImage: [{required: true, message: "请上传营业执照照片", trigger: "change"}]
})

const submitForm = async (formEl: FormInstance | undefined) => {
	if (!formEl) return
	await formEl.validate((valid, fields) => {
		if (valid) {
			console.log('submit!')
		} else {
			console.log('error submit!', fields)
		}
	})
}

const resetForm = (formEl: FormInstance | undefined) => {
	if (!formEl) return
	formEl.resetFields()
}

//密码登录
const checkLoginName = (rule: any, value: any, callback: any) => {
	const patten = /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/
	const limit = /^(?!_)(?![0-9])/
	if (value == '') return callback(new Error("用户名不能为空"))
	if (!patten.test(value)) return callback(new Error('只能包含中文、英文字母、数字和下划线'))
	if (!limit.test(value)) return callback(new Error('不能以数字或者下划线开头'))
	callback()
}
const checkLoginPassword = (rule: any, value: any, callback: any) => {
	//密码正则，，1个小写字母，1个数字，1-9
	let isP1 = computed(() => {
		const reg = /^(?=.*\d)(?=.*[A-z])[\da-zA-Z]{1,16}$/;

		return reg.test(value)
	})

	if (value === '') {
		callback(new Error("密码不能为空！"))
	} else if (value !== isP1) {
		callback(new Error("字母数字组合的1-16个字符"))
	} else {
		if (value !== '' && isP1) {
			if (ruleFormRefloginBypassword.value) return
			ruleFormRefloginBypassword.value.validateField("checkLoginPassword")
		}
		callback()
	}
}
//验证码登录
const checkLoginPhone = (rule: any, value: any, callback: any) => {
	let isTel = computed(() => {
		//手机号码表达式
		const reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
		return reg.test(loginParamByphone.phone);
	})
	if (value === '') {
		callback(new Error("手机号不能为空！"))
	} else if (!isTel) {
		callback(new Error("手机号格式不正确！"))
	} else {
		if (loginParamByphone.phone !== '' && isTel) {
			if (ruleFormRefloginByPhone.value) return
			ruleFormRefloginByPhone.value.validateField("checkLoginPhone")
		}
	}
}
const checkLoginPhoneCode = (rule: any, value: any, callback: any) => {
	if (value === '') {
		callback(new Error("验证码不能为空！"))
	}
	if (value.length !== 6) {
		callback(new Error("验证码格式不正确"))
	}
	if (loginParamByphone.phone !== '' && value.length == 6) {
		if (ruleFormRefloginByPhone.value) return
		ruleFormRefloginByPhone.value.validateField("checkLoginPhoneCode")
	}
}
//注册校验规则

//密码登录表单校验规则
const rules_forloginpassword = reactive<FormRules<typeof loginParamBypassword>>({
	name: [{validator: checkLoginName, trigger: 'blur'}],
	password: [
		{required:true,message:"请输入密码",trigger:"blur"},
		// {validator: validatePassword, trigger: 'blur'},
		{pattern:/^(?=.*\d)(?=.*[A-z])[\da-zA-Z]{1,9}$/,message:"密码格式不正确",trigger:"blur"}
	],
})
//手机号登录表单校验规则
const rules_forloginphone = reactive<FormRules<typeof loginParamByphone>>({
	phone: [{validator: checkLoginPhone, trigger: 'blur'}],
	phoneCode: [{validator: checkLoginPhoneCode, trigger: 'blur'}],
})
//验证手机号码格式
let isTel = computed(() => {
	//手机号码表达式
	const reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
	return reg.test(loginParamByphone.phone);
})
let isRTel = computed(() => {
	//手机号码表达式
	const reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
	return reg.test(userParam.phone);
})
//用户名正则，4到16位（字母，数字，下划线，减号）
let isName = computed(() => {
	const patten = /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/
	const limit = /^(?!_)(?![0-9])/
	return patten.test(loginParamBypassword.name) && limit.test(loginParamBypassword.name)
})
let isRName = computed(() => {
	const reg = /^[a-zA-Z0-9_-]{4,16}$/
	return reg.test(userParam.name)
})
//密码正则，，1个小写字母，1个数字，1-9
let isPassword = computed(() => {
	const reg = /^(?=.*\d)(?=.*[A-z])[\da-zA-Z]{1,16}$/;
	return reg.test(loginParamBypassword.password)
})
let isRPassword = computed(() => {
	const reg = /^(?=.*\d)(?=.*[A-z])[\da-zA-Z]{1,16}$/;
	return reg.test(userParam.password)
})
// 邮箱校验
// let isEmail = computed(() => {
// 	const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
// 	return reg.test(loginParam.email)
// })
let isREmail = computed(() => {
	const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
	return reg.test(userParam.email)
})

//倒计时结束，复位父组件标志
let resetFlag = (val: boolean) => {
	TimerFlag.value = val;
}
//注册请求
let registerbtn = async () => {
	let rt = await registerreq(userParam);
	alert(rt.data.msg)
}
//验证码获取请求
let activeGetCode = async () => {
	TimerFlag.value = true;
	try {
		let rs = await userStore.getTelCode(loginParamByphone.phone);
		loginParamByphone.phoneCode = userStore.phoneCode;
	} catch (e) {
	}
}
//验证码登录操作请求
const loginByPhonebtn = () => {
	userStore.loginByPhone(loginParamByphone);
}
//密码登录操作
let loginByPasswordBtn = () => {
	userStore.loginByPassword(loginParamBypassword);

}
//
const registerrole = ref([''])
const roleoptions = [
	{
		value: 1,
		label: '用户',
	},
	{
		value: 2,
		label: '商家',
	},
	{
		value: 3,
		label: '管理员',
	},
]


const userAvatarUpload: UploadProps['beforeUpload'] = (rawFile) => {
	if (rawFile.type !== 'image/jpg' || 'image/png') {
		ElMessage.error('Avatar picture must be JPG/PNG format!')
		return false
	} else if (rawFile.size / 1024 / 1024 > 2) {
		ElMessage.error('Avatar picture size can not exceed 2MB!')
		return false
	}
	return true
}

const avatarPerView = (uploadFile) =>{
	userParam.avatar = URL.createObjectURL(uploadFile.raw!)
}



//商家身份证正面上传
const EntityIDCardimg1Url = ref('')
const EntityIDCardimg1Success: UploadProps['onSuccess'] = (response, uploadFile) => {
	EntityIDCardimg1Url.value = URL.createObjectURL(uploadFile.raw!)
}
const EntityIDCardimg1Upload: UploadProps['beforeUpload'] = (rawFile) => {
	if (rawFile.type !== 'image/jpeg') {
		ElMessage.error('Avatar picture must be JPG format!')
		return false
	} else if (rawFile.size / 1024 / 1024 > 2) {
		ElMessage.error('Avatar picture size can not exceed 2MB!')
		return false
	}
	return true
}

//商家身份证反面上传
const EntityIDCardimg2Url = ref('')
const EntityIDCardimg2Success: UploadProps['onSuccess'] = (response, uploadFile) => {
	EntityIDCardimg2Url.value = URL.createObjectURL(uploadFile.raw!)
}
const EntityIDCardimg2Upload: UploadProps['beforeUpload'] = (rawFile) => {
	if (rawFile.type !== 'image/jpeg') {
		ElMessage.error('Avatar picture must be JPG format!')
		return false
	} else if (rawFile.size / 1024 / 1024 > 2) {
		ElMessage.error('Avatar picture size can not exceed 2MB!')
		return false
	}
	return true
}

//商家营业执照上传
const businessLicenceimgUrl = ref('')
const businessLicenceimgSuccess: UploadProps['onSuccess'] = (response, uploadFile) => {
	businessLicenceimgUrl.value = URL.createObjectURL(uploadFile.raw!)
}
const businessLicenceimgUpload: UploadProps['beforeUpload'] = (rawFile) => {
	if (rawFile.type !== 'image/jpeg') {
		ElMessage.error('Avatar picture must be JPG format!')
		return false
	} else if (rawFile.size / 1024 / 1024 > 2) {
		ElMessage.error('Avatar picture size can not exceed 2MB!')
		return false
	}
	return true
}

//商家logo上传
const businesslogoimgUrl = ref('')
const businesslogoSuccess: UploadProps['onSuccess'] = (response, uploadFile) => {
	businesslogoimgUrl.value = URL.createObjectURL(uploadFile.raw!)
}
const businesslogoUpload: UploadProps['beforeUpload'] = (rawFile) => {
	if (rawFile.type !== 'image/jpeg') {
		ElMessage.error('Avatar picture must be JPG format!')
		return false
	} else if (rawFile.size / 1024 / 1024 > 2) {
		ElMessage.error('Avatar picture size can not exceed 2MB!')
		return false
	}
	return true
}

//商家个人真实照片上传
const entityimgUrl = ref('')
const entityimgSuccess: UploadProps['onSuccess'] = (response, uploadFile) => {
	entityimgUrl.value = URL.createObjectURL(uploadFile.raw!)
}
const entityimgUpload: UploadProps['beforeUpload'] = (rawFile) => {
	if (rawFile.type !== 'image/jpeg') {
		ElMessage.error('Avatar picture must be JPG format!')
		return false
	} else if (rawFile.size / 1024 / 1024 > 2) {
		ElMessage.error('Avatar picture size can not exceed 2MB!')
		return false
	}
	return true
}



return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_dialog, {
      modelValue: _unref(userStore).visiable,
      "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_unref(userStore).visiable) = $event)),
      title: "用户登录",
      center: "",
      width: "30%"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tabs, { type: "border-card" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_tab_pane, { label: "账号注册" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_el_form, {
                    ref_key: "ruleFormregisterRef",
                    ref: ruleFormregisterRef,
                    model: _unref(userParam) || _unref(companyParam) ,
                    rules: userRules || companyRules,
                    "status-icon": ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_form_item, { prop: "registerrole" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_select, {
                            modelValue: registerrole.value,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((registerrole).value = $event)),
                            placeholder: "请选择注册身份"
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(roleoptions, (item) => {
                                return _createVNode(_component_el_option, {
                                  key: item.value,
                                  label: item.label,
                                  value: item.value
                                }, null, 8, ["label", "value"])
                              }), 64))
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _withDirectives(_createElementVNode("div", null, [
                        _createVNode(_component_el_form_item, { prop: "name" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              placeholder: "请输入用户名",
                              "prefix-icon": _unref(User),
                              modelValue: _unref(userParam).name,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(userParam).name) = $event))
                            }, null, 8, ["prefix-icon", "modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_form_item, { prop: "password" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              type: "password",
                              placeholder: "请输入密码",
                              "prefix-icon": _unref(Lock),
                              "show-password": "",
                              modelValue: _unref(userParam).password,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(userParam).password) = $event))
                            }, null, 8, ["prefix-icon", "modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_form_item, { prop: "repassword" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              type: "password",
                              placeholder: "请再次输入密码",
                              "prefix-icon": _unref(Lock),
                              "show-password": "",
                              modelValue: _unref(userParam).repassword,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(userParam).repassword) = $event))
                            }, null, 8, ["prefix-icon", "modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_form_item, { prop: "gender" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_radio_group, {
                              modelValue: _unref(userParam).gender,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(userParam).gender) = $event))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_radio, { label: 1 }, {
                                  default: _withCtx(() => _cache[18] || (_cache[18] = [
                                    _createTextVNode("男")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_component_el_radio, { label: 0 }, {
                                  default: _withCtx(() => _cache[19] || (_cache[19] = [
                                    _createTextVNode("女")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_form_item, { prop: "phone" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              placeholder: "请输入注册手机号码",
                              "prefix-icon": _unref(Lock),
                              modelValue: _unref(userParam).phone,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(userParam).phone) = $event))
                            }, null, 8, ["prefix-icon", "modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_form_item, { prop: "email" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              placeholder: "请输入电子邮箱地址",
                              "prefix-icon": _unref(Lock),
                              modelValue: _unref(userParam).email,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(userParam).email) = $event))
                            }, null, 8, ["prefix-icon", "modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_form_item, {
                          label: "头像",
                          prop: "avatar",
                          style: {"height":"100px","width":"100px"}
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_upload, {
                              class: "avatar-uploader",
                              ref: "userParam.avatar",
                              action: "#",
                              multiple: "",
                              "auto-upload": false,
                              "on-change": avatarPerView,
                              "show-file-list": false
                            }, {
                              default: _withCtx(() => [
                                (_unref(userParam).avatar)
                                  ? (_openBlock(), _createElementBlock("img", {
                                      key: 0,
                                      alt: "#",
                                      src: _unref(userParam).avatar,
                                      class: "avatar",
                                      style: {"height":"50px","width":"50px","border-radius":"50%"}
                                    }, null, 8, _hoisted_3))
                                  : (_openBlock(), _createBlock(_component_el_icon, {
                                      key: 1,
                                      class: "avatar-uploader-icon",
                                      style: {"height":"100px","width":"100px"}
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_unref(Plus))
                                      ]),
                                      _: 1
                                    }))
                              ]),
                              _: 1
                            }, 512)
                          ]),
                          _: 1
                        })
                      ], 512), [
                        [_vShow, registerrole.value === 1]
                      ]),
                      _withDirectives(_createElementVNode("div", null, [
                        _createVNode(_component_el_form_item, { prop: "companyName" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              placeholder: "请输入公司名称",
                              "prefix-icon": _unref(User),
                              modelValue: _ctx.companyParamcompanyName,
                              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.companyParamcompanyName) = $event))
                            }, null, 8, ["prefix-icon", "modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_form_item, { prop: "businessLicenseNumber" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              placeholder: "请输入营业执照号码",
                              "prefix-icon": _unref(User),
                              modelValue: _unref(companyParam).businessLicenseNumber,
                              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_unref(companyParam).businessLicenseNumber) = $event))
                            }, null, 8, ["prefix-icon", "modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_form_item, { prop: "industry" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              placeholder: "请输入行业",
                              "prefix-icon": _unref(User),
                              modelValue: _unref(companyParam).industry,
                              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_unref(companyParam).industry) = $event))
                            }, null, 8, ["prefix-icon", "modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_form_item, { prop: "establishmentData" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              placeholder: "请输入成立日期",
                              "prefix-icon": _unref(User),
                              modelValue: _unref(companyParam).establishmentData,
                              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_unref(companyParam).establishmentData) = $event))
                            }, null, 8, ["prefix-icon", "modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_form_item, { prop: "registerCapital" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              placeholder: "请输入注册资本",
                              "prefix-icon": _unref(User),
                              modelValue: _unref(companyParam).registerCapital,
                              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_unref(companyParam).registerCapital) = $event))
                            }, null, 8, ["prefix-icon", "modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_form_item, { prop: "mainBusiness" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              type: "text",
                              placeholder: "请输入主营业务",
                              "prefix-icon": _unref(User),
                              modelValue: _unref(companyParam).mainBusiness,
                              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_unref(companyParam).mainBusiness) = $event))
                            }, null, 8, ["prefix-icon", "modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_form_item, {
                          label: "身份证正面照",
                          prop: "entityIdCardImage1"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_upload, {
                              class: "avatar-uploader",
                              action: "https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15",
                              "show-file-list": false,
                              "on-success": EntityIDCardimg1Success,
                              "before-upload": EntityIDCardimg1Upload
                            }, {
                              default: _withCtx(() => [
                                (EntityIDCardimg1Url.value)
                                  ? (_openBlock(), _createElementBlock("img", {
                                      key: 0,
                                      src: EntityIDCardimg1Url.value,
                                      class: "avatar"
                                    }, null, 8, _hoisted_4))
                                  : (_openBlock(), _createBlock(_component_el_icon, {
                                      key: 1,
                                      class: "avatar-uploader-icon"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_unref(Plus))
                                      ]),
                                      _: 1
                                    }))
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_form_item, {
                          label: "身份证反面照",
                          prop: "entityIdCardImage2"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_upload, {
                              class: "avatar-uploader",
                              action: "https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15",
                              "show-file-list": false,
                              "on-success": EntityIDCardimg2Success,
                              "before-upload": EntityIDCardimg2Upload
                            }, {
                              default: _withCtx(() => [
                                (EntityIDCardimg2Url.value)
                                  ? (_openBlock(), _createElementBlock("img", {
                                      key: 0,
                                      src: EntityIDCardimg2Url.value,
                                      class: "avatar"
                                    }, null, 8, _hoisted_5))
                                  : (_openBlock(), _createBlock(_component_el_icon, {
                                      key: 1,
                                      class: "avatar-uploader-icon"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_unref(Plus))
                                      ]),
                                      _: 1
                                    }))
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_form_item, {
                          label: "营业执照照片",
                          prop: "licenceImage"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_upload, {
                              class: "avatar-uploader",
                              action: "https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15",
                              "show-file-list": false,
                              "on-success": businessLicenceimgSuccess,
                              "before-upload": businessLicenceimgUpload
                            }, {
                              default: _withCtx(() => [
                                (businessLicenceimgUrl.value )
                                  ? (_openBlock(), _createElementBlock("img", {
                                      key: 0,
                                      src: businessLicenceimgUrl.value ,
                                      class: "avatar"
                                    }, null, 8, _hoisted_6))
                                  : (_openBlock(), _createBlock(_component_el_icon, {
                                      key: 1,
                                      class: "avatar-uploader-icon"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_unref(Plus))
                                      ]),
                                      _: 1
                                    }))
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_form_item, {
                          label: "持证正面照",
                          prop: "entityCammerImage"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_upload, {
                              class: "avatar-uploader",
                              action: "https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15",
                              "show-file-list": false,
                              "on-success": entityimgSuccess,
                              "before-upload": entityimgUpload
                            }, {
                              default: _withCtx(() => [
                                (entityimgUrl.value)
                                  ? (_openBlock(), _createElementBlock("img", {
                                      key: 0,
                                      src: entityimgUrl.value,
                                      class: "avatar"
                                    }, null, 8, _hoisted_7))
                                  : (_openBlock(), _createBlock(_component_el_icon, {
                                      key: 1,
                                      class: "avatar-uploader-icon"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_unref(Plus))
                                      ]),
                                      _: 1
                                    }))
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_form_item, {
                          label: "公司logo",
                          prop: "logo"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_upload, {
                              class: "avatar-uploader",
                              action: "https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15",
                              "show-file-list": false,
                              "on-success": businesslogoSuccess,
                              "before-upload": businesslogoUpload
                            }, {
                              default: _withCtx(() => [
                                (businesslogoimgUrl.value)
                                  ? (_openBlock(), _createElementBlock("img", {
                                      key: 0,
                                      src: businesslogoimgUrl.value,
                                      class: "avatar"
                                    }, null, 8, _hoisted_8))
                                  : (_openBlock(), _createBlock(_component_el_icon, {
                                      key: 1,
                                      class: "avatar-uploader-icon"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_unref(Plus))
                                      ]),
                                      _: 1
                                    }))
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ], 512), [
                        [_vShow, registerrole.value === 2]
                      ])
                    ]),
                    _: 1
                  }, 8, ["model", "rules"])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_el_button, {
                    style: {"width":"90%"},
                    onClick: _unref(registerbtn),
                    type: "primary",
                    size: "default"
                  }, {
                    default: _withCtx(() => _cache[20] || (_cache[20] = [
                      _createTextVNode("用户注册 ")
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_el_tab_pane, { label: "账号登录" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_10, [
                  _withDirectives(_createElementVNode("div", null, [
                    _createVNode(_component_el_form, {
                      ref_key: "ruleFormRefloginBypassword",
                      ref: ruleFormRefloginBypassword,
                      model: _unref(loginParamBypassword),
                      rules: rules_forloginpassword,
                      "status-icon": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, { prop: "name" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              placeholder: "请输入用户名/手机号/电子邮箱地址登录",
                              "prefix-icon": _unref(User),
                              modelValue: _unref(loginParamBypassword).name,
                              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_unref(loginParamBypassword).name) = $event))
                            }, null, 8, ["prefix-icon", "modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_form_item, { prop: "password" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              placeholder: "请输入密码",
                              "prefix-icon": _unref(Lock),
                              "show-password": "",
                              type: "password",
                              modelValue: _unref(loginParamBypassword).password,
                              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_unref(loginParamBypassword).password) = $event))
                            }, null, 8, ["prefix-icon", "modelValue"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["model", "rules"]),
                    _createElementVNode("div", _hoisted_11, [
                      _createVNode(_component_el_button, {
                        style: {"width":"90%"},
                        onClick: _unref(loginByPasswordBtn),
                        disabled:  !_unref(isName) || !_unref(isPassword) ,
                        type: "primary",
                        size: "default"
                      }, {
                        default: _withCtx(() => _cache[21] || (_cache[21] = [
                          _createTextVNode("用户登录 ")
                        ])),
                        _: 1
                      }, 8, ["onClick", "disabled"])
                    ])
                  ], 512), [
                    [_vShow, _unref(wx_scane) === 0]
                  ]),
                  _withDirectives(_createElementVNode("div", _hoisted_12, null, 512), [
                    [_vShow, _unref(wx_scane) === 1]
                  ])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_el_tab_pane, { label: "手机登录" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_13, [
                  _withDirectives(_createElementVNode("div", null, [
                    _createVNode(_component_el_form, {
                      ref_key: "ruleFormRefloginByPhone",
                      ref: ruleFormRefloginByPhone,
                      model: _unref(loginParamByphone),
                      rules: rules_forloginphone,
                      "status-icon": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, { prop: "phone" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              placeholder: "请输入手机号码",
                              "prefix-icon": _unref(User),
                              modelValue: _unref(loginParamByphone).phone,
                              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_unref(loginParamByphone).phone) = $event))
                            }, null, 8, ["prefix-icon", "modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_form_item, { prop: "phoneCode" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              placeholder: "请输入手机验证码",
                              "prefix-icon": _unref(Lock),
                              modelValue: _unref(loginParamByphone).phoneCode,
                              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_unref(loginParamByphone).phoneCode) = $event))
                            }, null, 8, ["prefix-icon", "modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_form_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_button, {
                              disabled: !_unref(isTel) || _unref(TimerFlag) ? true : false,
                              onClick: _unref(activeGetCode)
                            }, {
                              default: _withCtx(() => [
                                (_unref(TimerFlag))
                                  ? (_openBlock(), _createBlock(CountDown, {
                                      key: 0,
                                      onResetFlag: _unref(resetFlag),
                                      TimerFlag: _unref(TimerFlag)
                                    }, null, 8, ["onResetFlag", "TimerFlag"]))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_14, "获取验证码"))
                              ]),
                              _: 1
                            }, 8, ["disabled", "onClick"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["model", "rules"]),
                    _createElementVNode("div", _hoisted_15, [
                      _createVNode(_component_el_button, {
                        style: {"width":"90%"},
                        onClick: loginByPhonebtn,
                        disabled: !_unref(isTel) || !_unref(loginParamByphone).phoneCode ,
                        type: "primary",
                        size: "default"
                      }, {
                        default: _withCtx(() => _cache[22] || (_cache[22] = [
                          _createTextVNode("用户登录 ")
                        ])),
                        _: 1
                      }, 8, ["disabled"]),
                      _createElementVNode("div", null, [
                        _createElementVNode("p", { onClick: changeScaner }, "微信扫码登录")
                      ])
                    ])
                  ], 512), [
                    [_vShow, _unref(wx_scane) === 0]
                  ]),
                  _withDirectives(_createElementVNode("div", _hoisted_16, null, 512), [
                    [_vShow, _unref(wx_scane) === 1]
                  ])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_el_tab_pane, { label: "找回密码" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_17, [
                  _withDirectives(_createElementVNode("div", _hoisted_18, null, 512), [
                    [_vShow, _unref(wx_scane) === 1]
                  ])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_el_tab_pane, { label: "微信登录" }, {
              default: _withCtx(() => _cache[23] || (_cache[23] = [
                _createTextVNode("微信登录")
              ])),
              _: 1
            }),
            _createVNode(_component_el_tab_pane, { label: "小程序端" }, {
              default: _withCtx(() => _cache[24] || (_cache[24] = [
                _createElementVNode("div", { class: "right_leftcontent" }, [
                  _createElementVNode("div", { class: "top" }, [
                    _createElementVNode("div", { class: "topiteml" }, [
                      _createElementVNode("img", {
                        class: "codeing",
                        src: "/images/icon/user1.png"
                      }),
                      _createElementVNode("svg", {
                        t: "1702744732774",
                        class: "icon",
                        viewBox: "0 0 1024 1024",
                        version: "1.1",
                        xmlns: "http://www.w3.org/2000/svg",
                        "p-id": "4288",
                        width: "32",
                        height: "32"
                      }, [
                        _createElementVNode("path", {
                          d: "M337.387283 341.82659c-17.757225 0-35.514451 11.83815-35.514451 29.595375s17.757225 29.595376 35.514451 29.595376 29.595376-11.83815 29.595376-29.595376c0-18.49711-11.83815-29.595376-29.595376-29.595375zM577.849711 513.479769c-11.83815 0-22.936416 12.578035-22.936416 23.6763 0 12.578035 11.83815 23.676301 22.936416 23.676301 17.757225 0 29.595376-11.83815 29.595376-23.676301s-11.83815-23.676301-29.595376-23.6763zM501.641618 401.017341c17.757225 0 29.595376-12.578035 29.595376-29.595376 0-17.757225-11.83815-29.595376-29.595376-29.595375s-35.514451 11.83815-35.51445 29.595375 17.757225 29.595376 35.51445 29.595376zM706.589595 513.479769c-11.83815 0-22.936416 12.578035-22.936416 23.6763 0 12.578035 11.83815 23.676301 22.936416 23.676301 17.757225 0 29.595376-11.83815 29.595376-23.676301s-11.83815-23.676301-29.595376-23.6763z",
                          fill: "#1afa29",
                          "p-id": "4289"
                        }),
                        _createElementVNode("path", {
                          d: "M510.520231 2.959538C228.624277 2.959538 0 231.583815 0 513.479769s228.624277 510.520231 510.520231 510.520231 510.520231-228.624277 510.520231-510.520231-228.624277-510.520231-510.520231-510.520231zM413.595376 644.439306c-29.595376 0-53.271676-5.919075-81.387284-12.578034l-81.387283 41.433526 22.936416-71.768786c-58.450867-41.433526-93.965318-95.445087-93.965317-159.815029 0-113.202312 105.803468-201.988439 233.803468-201.98844 114.682081 0 216.046243 71.028902 236.023121 166.473989-7.398844-0.739884-14.797688-1.479769-22.196532-1.479769-110.982659 1.479769-198.289017 85.086705-198.289017 188.67052 0 17.017341 2.959538 33.294798 7.398844 49.572255-7.398844 0.739884-15.537572 1.479769-22.936416 1.479768z m346.265896 82.867052l17.757225 59.190752-63.630058-35.514451c-22.936416 5.919075-46.612717 11.83815-70.289017 11.83815-111.722543 0-199.768786-76.947977-199.768786-172.393063-0.739884-94.705202 87.306358-171.653179 198.289017-171.65318 105.803468 0 199.028902 77.687861 199.028902 172.393064 0 53.271676-34.774566 100.624277-81.387283 136.138728z",
                          fill: "#1afa29",
                          "p-id": "4290"
                        })
                      ]),
                      _createElementVNode("p", null, "微信扫一扫关注"),
                      _createElementVNode("p", null, "快速预约")
                    ]),
                    _createElementVNode("div", { class: "topitemr" }, [
                      _createElementVNode("img", {
                        class: "codeing",
                        src: "/images/icon/user1.png"
                      }),
                      _createElementVNode("svg", {
                        t: "1702744975582",
                        class: "icon",
                        viewBox: "0 0 1024 1024",
                        version: "1.1",
                        xmlns: "http://www.w3.org/2000/svg",
                        "p-id": "5597",
                        width: "32",
                        height: "32"
                      }, [
                        _createElementVNode("path", {
                          d: "M406.109091 251.578182v488.727273a23.272727 23.272727 0 0 0 23.272727 23.272727h302.545455a372.363636 372.363636 0 0 0-325.818182-512z m116.363636 465.454545v-46.545454h186.181818v46.545454z",
                          fill: "#EDF6FF",
                          "p-id": "5598"
                        }),
                        _createElementVNode("path", {
                          d: "M359.563636 739.374545V252.043636a372.363636 372.363636 0 1 0 349.090909 557.149091h-279.272727a69.818182 69.818182 0 0 1-69.818182-69.818182z",
                          fill: "#EDF6FF",
                          "p-id": "5599"
                        }),
                        _createElementVNode("path", {
                          d: "M801.745455 64.465455h-372.363637a69.818182 69.818182 0 0 0-69.818182 69.818181v605.090909a69.818182 69.818182 0 0 0 69.818182 69.818182h372.363637a69.818182 69.818182 0 0 0 69.818181-69.818182v-605.090909a69.818182 69.818182 0 0 0-69.818181-69.818181z m23.272727 674.90909a23.272727 23.272727 0 0 1-23.272727 23.272728h-372.363637a23.272727 23.272727 0 0 1-23.272727-23.272728v-605.090909a23.272727 23.272727 0 0 1 23.272727-23.272727h69.818182a43.752727 43.752727 0 0 0 43.52 42.589091h145.454545a43.752727 43.752727 0 0 0 43.52-42.589091h69.818182a23.272727 23.272727 0 0 1 23.272728 23.272727z",
                          fill: "#040405",
                          "p-id": "5600"
                        }),
                        _createElementVNode("path", {
                          d: "M522.472727 669.556364h186.181818v46.545454h-186.181818z",
                          fill: "#2394FB",
                          "p-id": "5601"
                        })
                      ]),
                      _createElementVNode("p", null, "扫一扫下载"),
                      _createElementVNode("p", null, "App下载")
                    ])
                  ])
                ], -1)
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})