import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
const routes: Array<RouteRecordRaw> = [

    //前端路由
    {
        path: '/frontend',
        name: 'frontend',
        component: () => import('@/pages/homeView/Home.vue'),
        meta: {title: "首页"},
        children: [
            {
                path: '/',
                component: () => import('@/App.vue'),
            },
            {
                path: '/helper',
                meta: {title: "帮助界面"},
                component: () => import('@/pages/member/helper.vue')
            },
        ]
    },

    {
        path: '/search',
        name: 'search',
        meta: {title: "搜索界面"},
        component: () => import('@/pages/search/search.vue'),
        props(route) {
            return route.query
        },
    },
    {
        path: "/detail",
        name: "detail",
        meta: {title: "详情界面"},
        component: () => import('@/pages/details/detail.vue'),
        props(route) {
            return route.query
        },
    },

    {
        path: "/cart",
        name: "cart",
        meta: {title: "购物车"},
        component: () => import('@/pages/cart/cart.vue'),
        props(route) {
            return route.query
        },
    },

    {
        path: "/cartsList",
        name: "cartsList",
        meta: {title: "购物车列表"},
        component: () => import('@/pages/cart/cartsList.vue'),
        props(route) {
            return route.query
        },
    },

    {
        path: "/orderConfirm",
        name: "orderConfirm",
        meta: {title: "结算页"},
        component: () => import('@/pages/order/orderConfirm.vue'),
        props(route) {
            return route.query
        },
    },

    {
        path: "/orderDetail",
        name: "orderDetail",
        meta: {title: "订单详情"},
        component: () => import('@/pages/order/orderDetail.vue'),
        props(route) {
            return route.query
        },
    },

    {
        path: "/orderList",
        name: "orderList",
        meta: {title: "订单列表"},
        component: () => import('@/pages/order/orderList.vue'),
        props(route) {
            return route.query
        },
    },

    {
        path: "/orderPay",
        name: "orderPay",
        meta: {title: "支付页"},
        component: () => import('@/pages/order/orderPay.vue'),
        props(route) {
            return route.query
        }
    },

    {
        path:'/payDone',
        name:'payDone',
        meta:{title:"支付成功！"},
        component:()=>('@/page/order/payDone')
    },

    {
        path: "/CartBuffer",
        name: "CartBuffer",
        meta: {title: "购物车预览"},
        component: () => import('@/pages/cart/CartBuffer.vue'),
        props(route) {
            return route.query
        },
    },

    //前端详情界面
    {
        path: '/shop',
        component: () => import('@/pages/shop/shop.vue'),
        children: [
            {
                path: '/FactoryHome',
                meta: {title: "工厂首页"},
                component: () => import('@/pages/shop/FactoryHome/FactoryHome.vue')
            },
            {
                path: '/MainBusiness',
                meta: {title: "主营业务"},
                component: () => import('@/pages/shop/MainBusiness/MainBusiness.vue')
            },
            {
                path: '/FactoryDetail',
                meta: {title: "工厂详情"},
                component: () => import('@/pages/shop/FactoryDetail/FactoryDetail.vue')
            },
            {
                path: '/ContaxtFactory',
                meta: {title: "联系工厂"},
                component: () => import('@/pages/shop/ContaxtFactory/ContaxtFactory.vue')
            },
            {
                path: '/MsgOrFeedBack',
                meta: {title: "留言/反馈"},
                component: () => import('@/pages/shop/MsgOrFeedBack/MsgOrFeedBack.vue')
            },
            {
                path: '/SimilarFactory',
                meta: {title: "同类厂商"},
                component: () => import('@/pages/shop/SimilarFactory/SimilarFactory.vue')
            },

        ],

    },
    //员工通道
    {
        name: 'employeeChannel',
        path: '/employeeChannel',
        meta: {title: '员工通道'},
        component: () => import('@/pages/EmployeeChannel/EmployeeCenter.vue'),
        children: [
            {
                path: '/purchaseCenter',
                name: 'purchaseCenter',
                meta: {title: "采购中心"},
                component: () => import("@/pages/EmployeeChannel/PurchaseCenter/purchaseCenter.vue")
            },
        ]
    },

    //FastHelpPage
    {
        name:"FastHelpHome",
        path:"/FastHelpHome",
        meta:{title: "FastHelpHome"},
        component :() => import('@/FastHelpPage/FastHelpHome.vue'),
        children:[
            {
                path:'/FastHelpContactUs',
                name:'FastHelpContactUs',
                meta: {title: "FastHelpContactUs"},
                component:()=>('@/FasthelpPage/FastHelpContactUs.vue')
            },
            {
                path:'/FastHelpCulture',
                name:'FastHelpCulture',
                meta: {title: "FastHelpCulture"},
                component:()=>('@/FasthelpPage/FastHelpCulture.vue')
            },
            {
                path:'/FastHelpIntroduction',
                name:'FastHelpIntroduction',
                meta: {title: "FastHelpIntroduction"},
                component:()=>('@/FasthelpPage/FastHelpIntroduction.vue')
            },
            {
                path:'/FastHelpService',
                name:'FastHelpService',
                meta: {title: "FastHelpService"},
                component:()=>('@/FasthelpPage/FastHelpService.vue')
            },
            {
                path:'/FastHelpVision',
                name:'FastHelpVision',
                meta: {title: "FastHelpVision"},
                component:()=>('@/FasthelpPage/FastHelpVision.vue')
            },
        ]
    },

    //后台路由
    //后台登录注册页面
    {
        path:"/Backend-Login-register",
        name:"Backend-Login-register",
        meta:{title: "后台登录"},
        component:() => import("@/components/backEndHome/Backend-Login-register.vue"),

    },
    {
        path:"/login",
        name:"login",
        meta:{title: "会员登录"},
        component:() => import("@/components/login/login.vue"),
        props(route) {
            return route.query
        },
    },

    {
        path:"/register",
        name:"register",
        meta:{title: "会员登录"},
        component:() => import("@/components/login/register.vue"),
        props(route) {
            return route.query
        },
    },

    {
        path: '/backennd',
        name: 'backend',
        component: () => import('@/components/Common/index.vue'),
        children: [
            // <!--会员管理-->
            {
                path: '/userCenter',
                name: 'userCenter',
                meta: {title: "会员中心"},
                component: () => import('@/components/UserManage/userCenter.vue'),
                children: [
                    {
                        path: '/memberLevel',
                        name: 'menberLevel',
                        meta: {title: "会员等级"},
                        component: () => import('@/components/UserManage/memberLevel.vue')
                    },
                    {
                        path: '/memberInfo',
                        name: 'memberInfo',
                        meta: {title: "会员信息"},
                        component: () => import('@/components/UserManage/memberInfo.vue')
                    },
                    {
                        path: '/purchaserManage',
                        name: 'purchaserManage',
                        meta: {title: "采购人管理"},
                        component: () => import('@/components/UserManage/purchaserManage.vue')
                    },


                ]
            },
            // <!--小程序后台-->
            {
                path: '/appHome',
                name: 'appHome',
                meta: {title: "小程序后台首页"},
                component: () => import('@/components/SmallProgSupport/appHome.vue'),
                children: [
                    {
                        path: '/message-center',
                        name: 'MessageCenter',
                        meta: {title: "消息中心"},
                        component: () => import('@/components/SmallProgSupport/MessageCenter.vue')
                    },
                    {
                        path: '/help-request',
                        name: 'HelpRequest',
                        meta: {title: "协助请求界面"},
                        component: () => import('@/components/SmallProgSupport/HelpRequest.vue')
                    },
                    {
                        path: '/ReceiveMistakeInfo',
                        name: 'ReceiveMistakeInfo',
                        meta: {title: "反馈中心"},
                        component: () => import('@/components/SmallProgSupport/ReceiveTroubleEvent.vue')
                    },
                    {
                        path: '/CloseEvent',
                        name: 'CloseEvent',
                        meta: {title: "时间关闭"},
                        component: () => import('@/components/SmallProgSupport/EventCloseDone.vue')
                    },
                ]

            },


            // <!--商品管理-->
            {
                path: '/productManage',
                name: 'productManage',
                meta: {title: "商品管理"},
                component: () => import('@/components/productManage/productManage.vue'),
                children: [
                    {
                        path: '/SPUManage',
                        name: 'SPUManage',
                        meta: {title: "SPU管理"},
                        component: () => import('@/components/productManage/SPUmanage.vue'),

                    },
                    {
                        path: '/SKUManages',
                        name: 'SKUManages',
                        meta: {title: "SKU管理"},
                        component: () => import('@/components/productManage/SKUManages.vue'),

                    },
                    {
                        path: '/productPublish',
                        name: 'productPublish',
                        meta: {title: "商品发布"},
                        component: () => import('@/components/productManage/productPublish.vue'),
                        // props: true
                        children: [
                            {
                                path: '/step1',
                                name: 'step1',
                                meta: {title: "step1-基本信息填写"},
                                component: () => import('@/components/productManage/PublishComponents/step1.vue'),
                            },
                            {
                                path: '/step2',
                                name: 'step2',
                                meta: {title: "step2-基本属性"},
                                component: () => import('@/components/productManage/PublishComponents/step2.vue'),
                                props(route) {
                                    return route.query
                                },
                            },
                            {
                                path: '/step3',
                                name: 'step3',
                                meta: {title: "step3-销售属性"},
                                component: () => import('@/components/productManage/PublishComponents/step3.vue'),
                                props(route) {
                                    return route.query
                                },
                            },
                            {
                                path: '/step4',
                                name: 'step4',
                                meta: {title: "step4"},
                                component: () => import('@/components/productManage/PublishComponents/step4.vue'),
                                props(route) {
                                    return route.query
                                },
                            },
                            {
                                path: '/step5',
                                name: 'step5',
                                meta: {title: "step5"},
                                component: () => import('@/components/productManage/PublishComponents/step5.vue')
                            },
                        ]
                    },

                ]
            },


            // <!--角色管理-->
            {
                path: '/roleManage',
                name: 'roleManage',
                meta: {title: "角色管理"},
                component: () => import('@/components/UserManage/roleManage.vue')
            },
            //<!-- 图片管理-->
            {
                path: '/swiperPublish',
                name: 'swiperPublish',
                meta: {title: "轮播图公共界面"},
                component:()=>import('@/components/ImageManage/swiperPublish.vue'),
                children: [
                    {
                        path: '/swiperManage',
                        name: 'swiperManage',
                        meta: {title: "轮播图管理"},
                        component:()=>import('@/components/ImageManage/swiperManage.vue'),
                    },
                    {
                        path: '/IconManage',
                        name: 'IconManage',
                        meta: {title: "分类图标管理"},
                        component: ()=>import('@/components/ImageManage/IconManage.vue')
                    },
                    {
                        path: '/backImgManage',
                        name: 'backImgManage',
                        meta: {title: "背景图片管理"},
                        component: ()=>import('@/components/ImageManage/backImgManage.vue')
                    },
                ]
            },




            // <!--产品管理-->
            {
                path: '/Category',
                name: 'Category',
                meta: {title: "分类维护"},
                component: () => import('@/components/Product/Category.vue')
            },
            //品牌管理
            {
                path: '/Brand',
                name: 'Brand',
                meta: {title: "品牌管理"},
                component: () => import('@/components/Product/Brand.vue')
            },
            //属性分组
            {
                path: '/AttrGroup',
                name: 'AttrGroup',
                meta: {title: "属性分组"},
                component: () => import('@/components/Product/AttrGroup.vue')
            },
            //属性分类
            {
                path: '/attr',
                name: 'attr',
                meta: {title: "规格参数"},
                component: () => import('@/components/Product/attr.vue')
            },


            //仓库管理
            {
                path: '/warePublic',
                name: 'warePublic',
                meta: {title: "仓库管理"},
                component: () => import("@/components/wareManages/warePublic.vue"),
                children: [
                    {
                        name: 'wareManage',
                        path: '/wareManage',
                        meta: {title: "仓库管理"},
                        component: () => import("@/components/wareManages/wareManage.vue"),

                    },
                    {
                        name: 'wareOrderTask',
                        path: '/wareOrderTask',
                        meta: {title: "库存工作单"},
                        component: () => import("@/components/wareManages/wareOrderTask.vue"),

                    },
                    {
                        name: 'wareSku',
                        path: '/wareSku',
                        meta: {title: "商品库存管理"},
                        component: () => import("@/components/wareManages/wareSku.vue"),
                        props(route) {
                            return route.query
                        },
                    },
                    {
                        name: 'purchaseDetail',
                        path: '/purchaseDetail',
                        meta: {title: "采购申请单"},
                        component: () => import("@/components/wareManages/purchaseDetail.vue"),

                    },

                    {
                        name: 'purchases',
                        path: '/purchases',
                        meta: {title: "采购汇总单"},
                        component: () => import("@/components/wareManages/purchases.vue"),

                    },


                ]

            },


        ]

    },
    //  <!--云数据 -->，大数据
    {
        path: '/cloudmain',
        name: '/cloudmain',
        meta: {title: "云数据主页"},
        component: () => import('@/components/CouldDate/cloudMain.vue'),
        children: [
            {
                path: '/monitor',
                meta: {title: "监控界面"},
                component: () => import('@/components/BigScreenView/Monitor.vue')
            },
            {
                path: '/FactoryDate',
                name: "FactoryDate",
                meta: {title: "工厂数据"},
                component: () => import('@/components/CouldDate/FactoryDate.vue')
            },
            {
                path: '/DateAnalisy',
                name: "DateAnalisy",
                meta: {title: "数据分析"},
                component: () => import('@/components/CouldDate/DateAnalisy.vue')
            },
            {
                path: '/DateManage',
                name: "DateManage",
                meta: {title: "数据管理"},
                component: () => import('@/components/CouldDate/DateManage.vue')
            },
            {
                path: '/AlarmInfo',
                name: "AlarmInfo",
                meta: {title: "报警信息"},
                component: () => import('@/components/CouldDate/AlarmInfo.vue')
            },

            {
                path: '/logInfo',
                name: "logInfo",
                meta: {title: "运行日志"},
                component: () => import('@/components/CouldDate/logInfo.vue')
            },


            {
                path: '/CycliderInfo',
                name: "CycliderInfo",
                meta: {title: "气缸信息"},
                component: () => import('@/components/PLC/CycliderInfo.vue')
            },
            {
                path: '/ServoInfo',
                name: "ServoInfo",
                meta: {title: "伺服信息"},
                component: () => import('@/components/PLC/ServoInfo.vue')
            },
            {
                path: "/plcProperties",
                name: "plcProperties",
                meta: {title: "plc_properties"},
                component: () => import('@/components/plcSet/plcProperties.vue'),
                props(route) {
                    return route.query
                },
            },

        ]
    },

]
const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return {
            left: 0,
            top: 0
        }
    }
})


export default router;
