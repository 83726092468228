import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, isRef as _isRef, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "titleBox" }
const _hoisted_2 = { class: "headerInfoBox" }
const _hoisted_3 = { class: "imgBox" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "signClass" }
const _hoisted_6 = { class: "centotBody" }
const _hoisted_7 = { class: "bodyMain" }
const _hoisted_8 = { class: "mainleft" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "mainright" }
const _hoisted_11 = {
  key: 0,
  class: "infoItem"
}
const _hoisted_12 = { class: "myinfomation" }
const _hoisted_13 = { class: "submitbtn" }
const _hoisted_14 = {
  key: 1,
  class: "infoItem"
}
const _hoisted_15 = {
  key: 2,
  class: "infoItem"
}
const _hoisted_16 = {
  key: 3,
  class: "infoItem"
}
const _hoisted_17 = {
  key: 4,
  class: "infoItem"
}
const _hoisted_18 = {
  key: 5,
  class: "infoItem"
}

import {onMounted, reactive, ref} from "vue";
import userUserStore from "@/stores/user";
import Router from "@/router"
import {ElMessageBox, ElMessage} from "element-plus";
import {deleteMemberAddress_req, getOrderList_req} from "@/axiosAPI/oms/oms.js";
import {updataMemberInfo_req, getAddress_req} from '@/axiosAPI/member/memberManage.js'
import Add_edit_addressDialog from "@/pages/member/add_edit_addressDialog.vue";


export default /*@__PURE__*/_defineComponent({
  ...{
	name: "memberInfo",
	inheritAttrs: false
},
  __name: 'memberInfo',
  setup(__props) {


let baseurl = process.env.VUE_APP_BASE_URL //图片上传路径设置
let mainHeight = ref()
let px = ref("px")
let birth = ref((new Date()))
let drawerSize = ref()
let sizeUnit = ref("%")
let params = {
	currentPage: 1,
	pageSize: 5,
	sort: 'desc',
	paymentTime: null,
	status: null
}
setInterval(() => {
	mainHeight.value = window.innerHeight - 120 + px.value
})
let formData = reactive({
	id: null,
	token: null,
	redirect: null,
	name: null,
	password: null,
	phone: null,
	levelId: null,
	nickName: null,
	email: null,
	header: null,
	genders: null,
	birth: new Date(),
	city: null,
	job: null,
	sign: null,
	sourceType: null,
	integreat: null,
	growth: null,
	status: null,
	createTime: null,
	socialUid: null,
	accessToken: null,
	expoiresIn: null,
})
const addressData = reactive([
	{
		id: null,
		memberId: null,
		name: null,
		phone: null,
		postCode: null,
		province: null,
		city: null,
		town: null,
		detailAddress: null,
		areaCode: null,
		defaultStatus: null,
		isDelete: null,
		createTime: null,
		updateTime: null,
	}
])
let sendData = ref(null)
let tabPosition = ref('left')
let userStore = userUserStore()
let table = ref(false)
let tableData = ref()
let indexNum = ref(0)
onMounted(() => {
	let info = JSON.parse(localStorage.getItem("userInfo"));
	Object.assign(formData, info)
	getaddress()
})
let manuList = ref([
	{btnname: "个人信息", selected: true},
	{btnname: "账号设置", selected: false},
	{btnname: "我的钱包", selected: false},
	{btnname: "我的服务", selected: false},
	{btnname: "我的地址", selected: false},
	{btnname: "我的订单", selected: false},
])

// defineProps(['refreshAddress'])
function manuClick(list, item, index) {
	if (index === 0) {
		Object.assign(formData, userStore.userInfo)
	}
	if (index === 4 || index === 5) {
		drawerSize.value = 100 + sizeUnit.value
	} else {
		drawerSize.value = 50 + sizeUnit.value
	}
	if (index === 4) {
		getaddress()
	}
	if (index === 5) {
		// getOrderList()
		Router.push("/orderList")
	}
	indexNum.value = index
	list.forEach(i => {
		i.selected = false
	})
	item.selected = true
}

function refreshAddress() {
	getaddress()
}

function onSubmit() {
	ElMessageBox.confirm("确定提交设置吗").then(() => {
		updataMemberInfo()
		ElMessage("提交成功")
	}).catch((e) => {
		ElMessage(e)
	})

}

function insertAddress() {
	userStore.addressDialogShow = true
	userStore.addressDialogTitle = "地址添加界面"
	sendData.value = formData.id
}

function editBtn(row, index) {
	userStore.addressDialogShow = true
	userStore.addressDialogTitle = "地址编辑界面"
	sendData.value = row
}

function deleteBtn(row, index) {
	ElMessageBox.confirm("确定删除这条地址吗？").then(() => {
		deleteMemberAddress(row)
	}).catch((e) => {
		ElMessage(e)
	})

}

async function deleteMemberAddress(row) {
	await deleteMemberAddress_req(row)
	ElMessage("删除成功！")
	getaddress()
}

async function updataMemberInfo() {

	formData.birth = birth.value

	await updataMemberInfo_req(formData)

}

function getaddress() {
	addressGetAndUpdata()
}

async function addressGetAndUpdata() {
	let data = {
		memberId: null
	}
	data.memberId = formData.id
	let res = await getAddress_req(data)
	Object.assign(addressData, res.data.data)
}

async function getOrderList() {
	let res = await getOrderList_req(params)
	tableData.value = res.data.data
}


//logo上传
const handleHeaderUploadSuccess = (res, file) => {
	if (res.code == 200) {
		formData.header = res.data
		updataMemberInfo()
	} else {
		ElMessage.error("请求发送，但没有成功！")
	}
}
const handleHeaderUploadError = (res, file) => {
	ElMessage.error("上传失败！" + res.message)
}
const beforeUpload = (file) => {
	let isjpg = file.type === 'image/jpg' || 'image/jpeg'
	let ispng = file.type === 'image/png'
	let israng = file.size / 1024 / 1024 < 2

	if (!isjpg && !ispng) {
		ElMessage.error("图片格式请确保是jpg 或png 格式")
	}
	if (!israng) {
		ElMessage.error("图片大小不得大于2M")
	}
}



return (_ctx: any,_cache: any) => {
  const _component_Plus = _resolveComponent("Plus")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_edit = _resolveComponent("edit")!
  const _component_delete = _resolveComponent("delete")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_el_drawer = _resolveComponent("el-drawer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_drawer, {
      modelValue: _unref(userStore).userCenterDrawerShow,
      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_unref(userStore).userCenterDrawerShow) = $event)),
      direction: "rtl",
      size: _unref(drawerSize)
    }, {
      header: _withCtx(({ close, titleId, titleClass }) => [
        _createElementVNode("div", _hoisted_1, [
          _cache[11] || (_cache[11] = _createElementVNode("div", { class: "logoBox" }, [
            _createElementVNode("img", {
              style: {"height":"40px","width":"160px"},
              src: "/images/logo/logoNew.png",
              alt: "logo"
            })
          ], -1)),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_el_upload, {
                class: "avatar-uploader",
                action: _unref(baseurl) + '/file/upload',
                "show-file-list": false,
                multiple: "",
                "on-success": handleHeaderUploadSuccess,
                "on-error": handleHeaderUploadError,
                "before-upload": beforeUpload
              }, {
                default: _withCtx(() => [
                  (_unref(formData).header)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        src: _unref(formData).header,
                        class: "avatar",
                        style: {"height":"50px","width":"50px"},
                        alt: "品牌logo"
                      }, null, 8, _hoisted_4))
                    : (_openBlock(), _createBlock(_component_el_icon, {
                        key: 1,
                        style: {"height":"50px","width":"50px","border-radius":"50%"},
                        class: "avatar-uploader-icon"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Plus)
                        ]),
                        _: 1
                      }))
                ]),
                _: 1
              }, 8, ["action"])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", null, _toDisplayString(_unref(formData).name), 1),
              _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(formData).sign), 1)
            ])
          ])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_scrollbar, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(manuList), (item, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "buttonItem",
                      key: index,
                      onClick: ($event: any) => (manuClick(_unref(manuList),item,index))
                    }, [
                      _createElementVNode("div", {
                        class: _normalizeClass({active: item.selected})
                      }, _toDisplayString(item.btnname), 3)
                    ], 8, _hoisted_9))
                  }), 128))
                ]),
                _createElementVNode("div", _hoisted_10, [
                  (_unref(indexNum) === 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                          _createVNode(_component_el_form, {
                            model: _unref(formData),
                            height: _unref(mainHeight),
                            "label-width": "auto",
                            style: {"width":"100%"}
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_form_item, { label: "昵称" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    modelValue: _unref(formData).nickName,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(formData).nickName) = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, { label: "会员等级" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    modelValue: _unref(formData).levelId,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(formData).levelId) = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, { label: "手机" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    modelValue: _unref(formData).phone,
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(formData).phone) = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, { label: "邮箱" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    modelValue: _unref(formData).email,
                                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(formData).email) = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, { label: "积分" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    modelValue: _unref(formData).integreat,
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(formData).integreat) = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, { label: "成长值" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    modelValue: _unref(formData).growth,
                                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(formData).growth) = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, { label: "所在城市" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    modelValue: _unref(formData).city,
                                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(formData).city) = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, { label: "出生日期" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_col, { span: 11 }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_date_picker, {
                                        modelValue: _unref(birth),
                                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_isRef(birth) ? (birth).value = $event : birth = $event)),
                                        "show-today": "",
                                        placeholder: "选择出生日期",
                                        style: {"width":"100%"}
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, { label: "性别" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_radio_group, {
                                    modelValue: _unref(formData).genders,
                                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_unref(formData).genders) = $event))
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_radio, { label: 1 }, {
                                        default: _withCtx(() => _cache[12] || (_cache[12] = [
                                          _createTextVNode("男")
                                        ])),
                                        _: 1
                                      }),
                                      _createVNode(_component_el_radio, { label: 0 }, {
                                        default: _withCtx(() => _cache[13] || (_cache[13] = [
                                          _createTextVNode("女")
                                        ])),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, { label: "个性签名" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    modelValue: _unref(formData).sign,
                                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_unref(formData).sign) = $event)),
                                    type: "textarea"
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["model", "height"])
                        ]),
                        _createElementVNode("div", _hoisted_13, [
                          _createVNode(_component_el_button, {
                            type: "primary",
                            onClick: onSubmit
                          }, {
                            default: _withCtx(() => _cache[14] || (_cache[14] = [
                              _createTextVNode("提交")
                            ])),
                            _: 1
                          })
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (_unref(indexNum) === 1)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                        _createVNode(_component_el_table, {
                          height: _unref(mainHeight),
                          data: _unref(tableData)
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_table_column, {
                              property: "date",
                              label: "Date",
                              width: "150"
                            }),
                            _createVNode(_component_el_table_column, {
                              property: "name",
                              label: "Name",
                              width: "200"
                            }),
                            _createVNode(_component_el_table_column, {
                              property: "address",
                              label: "Address"
                            })
                          ]),
                          _: 1
                        }, 8, ["height", "data"])
                      ]))
                    : _createCommentVNode("", true),
                  (_unref(indexNum) === 2)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                        _createVNode(_component_el_table, {
                          height: _unref(mainHeight),
                          data: _unref(tableData)
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_table_column, {
                              property: "date",
                              label: "Date",
                              width: "150"
                            }),
                            _createVNode(_component_el_table_column, {
                              property: "name",
                              label: "Name",
                              width: "200"
                            }),
                            _createVNode(_component_el_table_column, {
                              property: "address",
                              label: "Address"
                            })
                          ]),
                          _: 1
                        }, 8, ["height", "data"])
                      ]))
                    : _createCommentVNode("", true),
                  (_unref(indexNum) === 3)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                        _createVNode(_component_el_table, {
                          height: _unref(mainHeight),
                          data: _unref(tableData)
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_table_column, {
                              property: "date",
                              label: "Date",
                              width: "150"
                            }),
                            _createVNode(_component_el_table_column, {
                              property: "name",
                              label: "Name",
                              width: "200"
                            }),
                            _createVNode(_component_el_table_column, {
                              property: "address",
                              label: "Address"
                            })
                          ]),
                          _: 1
                        }, 8, ["height", "data"])
                      ]))
                    : _createCommentVNode("", true),
                  (_unref(indexNum) === 4)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                        _createVNode(_component_el_table, {
                          height: _unref(mainHeight),
                          data: addressData
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_table_column, {
                              prop: "name",
                              label: "name"
                            }),
                            _createVNode(_component_el_table_column, {
                              prop: "phone",
                              label: "phone"
                            }),
                            _createVNode(_component_el_table_column, {
                              prop: "postCode",
                              label: "postCode"
                            }),
                            _createVNode(_component_el_table_column, {
                              prop: "province",
                              label: "province"
                            }),
                            _createVNode(_component_el_table_column, {
                              prop: "city",
                              label: "city"
                            }),
                            _createVNode(_component_el_table_column, {
                              prop: "town",
                              label: "town"
                            }),
                            _createVNode(_component_el_table_column, {
                              prop: "detailAddress",
                              label: "detailAddress"
                            }),
                            _createVNode(_component_el_table_column, {
                              prop: "areaCode",
                              label: "area_code"
                            }),
                            _createVNode(_component_el_table_column, {
                              prop: "defaultStatus",
                              label: "defaultStatus"
                            }, {
                              default: _withCtx((scope) => [
                                (scope.row.defaultStatus === 0)
                                  ? (_openBlock(), _createBlock(_component_el_tag, {
                                      key: 0,
                                      type: "success"
                                    }, {
                                      default: _withCtx(() => _cache[15] || (_cache[15] = [
                                        _createTextVNode("未设置默认")
                                      ])),
                                      _: 1
                                    }))
                                  : _createCommentVNode("", true),
                                (scope.row.defaultStatus === 1)
                                  ? (_openBlock(), _createBlock(_component_el_tag, {
                                      key: 1,
                                      type: "success"
                                    }, {
                                      default: _withCtx(() => _cache[16] || (_cache[16] = [
                                        _createTextVNode("已设为默认")
                                      ])),
                                      _: 1
                                    }))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_el_table_column, { align: "center" }, {
                              header: _withCtx(() => [
                                _createVNode(_component_el_button, {
                                  onClick: insertAddress,
                                  type: "primary"
                                }, {
                                  default: _withCtx(() => _cache[17] || (_cache[17] = [
                                    _createTextVNode("添加地址")
                                  ])),
                                  _: 1
                                })
                              ]),
                              default: _withCtx((scope) => [
                                _createVNode(_component_el_button, {
                                  type: "success",
                                  size: "small",
                                  onClick: ($event: any) => (editBtn(scope.row,scope,_ctx.index))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_icon, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_edit)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"]),
                                _createVNode(_component_el_button, {
                                  type: "danger",
                                  size: "small",
                                  onClick: ($event: any) => (deleteBtn(scope.row,scope,_ctx.index))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_icon, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_delete)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["height", "data"])
                      ]))
                    : _createCommentVNode("", true),
                  (_unref(indexNum) === 5)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                        _createVNode(_component_el_table, {
                          height: _unref(mainHeight),
                          data: _unref(tableData)
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_table_column, {
                              prop: "orderSn",
                              label: "订单编号",
                              width: "350px",
                              align: "center"
                            }),
                            _createVNode(_component_el_table_column, {
                              prop: "phone",
                              label: "phone"
                            }),
                            _createVNode(_component_el_table_column, {
                              prop: "post_code",
                              label: "post_code"
                            }),
                            _createVNode(_component_el_table_column, {
                              prop: "province",
                              label: "province"
                            }),
                            _createVNode(_component_el_table_column, {
                              prop: "city",
                              label: "city"
                            }),
                            _createVNode(_component_el_table_column, {
                              prop: "region",
                              label: "region"
                            }),
                            _createVNode(_component_el_table_column, {
                              prop: "detail_address",
                              label: "detail_address"
                            }),
                            _createVNode(_component_el_table_column, {
                              prop: "area_code",
                              label: "area_code"
                            }),
                            _createVNode(_component_el_table_column, {
                              prop: "default_status",
                              label: "default_status"
                            })
                          ]),
                          _: 1
                        }, 8, ["height", "data"])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "size"]),
    _createElementVNode("div", null, [
      _createVNode(Add_edit_addressDialog, {
        onRefreshAddress: refreshAddress,
        sendData: _unref(sendData)
      }, null, 8, ["sendData"])
    ])
  ]))
}
}

})