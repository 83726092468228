import request from "@/utils/request";

const  BASE_URL  = process.env.VUE_APP_SERVER_PATH

/**
 * 请求三级菜单菜单列表
 * @param Categoryedit
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const category_backend_Req = () => {
    return request.post('/pms-category/info',)
}

export const category_backend_ReqByid = (id) => {
    return request.get(
        `/pms-category/info/${id}`)
}


/**
 * 菜单删除请求
 * @param Categoryedit
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const category_deleteMenu_Req = (ids) => {
    return request.post(
        '/pms-category/deleteMenu',
        {
            ids: ids
        })
}

/**
 * 菜单添加
 * @param category
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const category_addMenu_Req = (category) => {
    return request.post(
        '/pms-category/addMenu',
        {
            name: category.name,
            prentCid: category.prentCid,
            catLevel: category.catLevel,
            showStatus: category.showStatus,
            sort: category.sort,
            icon: category.icon,
            productUnit: category.productUnit,
            productCount: category.productCount,
            createTime: category.createTime,
            updateTime: category.updateTime
        })
}

/**
 * 菜单修改
 * @param category
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const category_editMenu_Req = (category) => {
    return request.post(
        '/pms-category/editMenu',
        {
            id: category.id,
            name: category.name,
            icon: category.icon,
            productUnit: category.productUnit,
        })
}


/**
 * 根据catelogid查询所有节点信息
 * @param reqparam
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const findCatelogPathreq = (reqparam) => {
    return request.post(
        "/pms-category/findByCatelogpaths",
        {
            id: reqparam.id
        }
    )
}


/**
 * 导入导出
 */
export const excelImport_req = () => {
    return request.post("/pms-category/excelImport")
}



/**
 * 导出操作
 * @returns {*}
 */
export const excelExport_req = () => {
    return request({
           url: "/pms-category/excelExport",
           method:'post',
           responseType: 'blob',
           headers:{
               'content-Type':'application/x-www-from-urlencoded'
           },
    })
}

