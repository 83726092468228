import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  slot: "footer",
  class: "dialog-footer",
  align: "center"
}

import userUserStore from "@/stores/user";
import {getCarcascadeList_req,addMemberAddress_req,editMemberAddress_req} from '@/axiosAPI/oms/oms.js'
import {onMounted, ref,reactive,computed} from "vue";
import {FormRules,FormInstance,} from "element-plus";

export default /*@__PURE__*/_defineComponent({
  ...{
	name: "add_edit_addressDialog",
	inheritAttrs:false
},
  __name: 'add_edit_addressDialog',
  props: ['sendData'],
  emits: ['refreshAddress'],
  setup(__props, { emit: __emit }) {

const defprp = __props

let addrRef = ref<FormInstance>()
let useStore = userUserStore()
onMounted(()=>{
	getCascade()
})
function dialogOpend(){
	if (useStore.addressDialogTitle === "地址编辑界面"){
				let arr = []
				arr.push(defprp.sendData.province)
				arr.push(defprp.sendData.city)
				if (defprp.sendData.town && useStore.addressDialogTitle === "地址编辑界面"){
					arr.push(defprp.sendData.town)
				}else{
					addressData.id = defprp.sendData.id
				}
				addressData.id = defprp.sendData.id
				addressData.memberId=defprp.sendData.memberId,
				addressData.name=defprp.sendData.name,
				addressData.phone=defprp.sendData.phone,
				addressData.province = defprp.sendData.province,
				addressData.city = defprp.sendData.city,
				addressData.town = defprp.sendData.towm,
				addressData.postCode=defprp.sendData.postCode,
				addressData.selectAddr=arr,
				addressData.detailAddress=defprp.sendData.detailAddress
	}
}

function dialogclosed (){
	resetData()
}


const addressData = reactive({
	id:null,
	memberId:'',
	name:'',
	phone:'',
	postCode:'',
	province:'',
	city:'',
	town:'',
	selectAddr:[],
	detailAddress:'',
})
const checkPhone = (rule: any, value: any, callback: any) => {
	if (value === '') {
		callback(new Error("手机号不能为空！"))
	} else if ( value.length!= 11) {
		callback(new Error("手机号格式不正确！"))
	} else {
		callback()
	}
}

const rules = reactive<FormRules>({
	name:[{required:true,message:"请输入收件人姓名",trigger:"blur"}],
	phone:[
			{validator:checkPhone,trigger:'blur'}],
	postCode:[{required:true,message:"请输入邮政编码",trigger:"blur"}],
	selectAddr:[
		{required:true,message:"请选择县/区域",trigger:"blur"},
	],
	detailAddress:[
		{required:true,message:"请输入详细地址",trigger:"blur"},
		{min: 10, message: '请详细输入您的地址，包括乡镇，街道，门牌号等', trigger: 'blur' }]
})

const options =  reactive([])
function getFocus(){
	getCascade()
}
function getCascade(){
	getCarcascadeList()
}


function selectChange(){
	addressData.province =addressData.selectAddr[0]
	addressData.city=addressData.selectAddr[1]
	addressData.town=addressData.selectAddr[2]
}

let props = reactive({
	//要发送的字段
	value: "name",
	label: "name",
	//子集合
	children: "children"
})

async function submit(forE1:FormInstance|undefined){
if (!forE1) return
	await forE1.validate((valid,field)=>{
		if (valid){
			if(useStore.addressDialogTitle === "地址编辑界面"){
				editMemberAddress()
			}
			if (useStore.addressDialogTitle === "地址添加界面"){
				addMemberAddress()
			}
		}else {
		}
	})
}
const emit = __emit


async function editMemberAddress(){
	await editMemberAddress_req(addressData)
	emit('refreshAddress')
	resetData()
	useStore.addressDialogShow = false
}


async function addMemberAddress(){
	addressData.memberId = defprp.sendData
	await addMemberAddress_req(addressData)
	emit('refreshAddress')
	resetData()
	useStore.addressDialogShow = false

}

async function getCarcascadeList(){
 let res = await getCarcascadeList_req()
	Object.assign(options,res.data.data)

}
function dialogCancelBtn(){
	useStore.addressDialogShow = false
}




function resetData(){
		addressData.memberId='',
		addressData.name='',
		addressData.phone='',
		addressData.postCode='',
		addressData.province='',
		addressData.city='',
		addressData.town='',
		addressData.selectAddr=[],
		addressData.detailAddress=''
}



return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_cascader = _resolveComponent("el-cascader")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_dialog, {
      align: "center",
      title: _unref(useStore).addressDialogTitle,
      onOpened: dialogOpend,
      onClosed: dialogclosed,
      modelValue: _unref(useStore).addressDialogShow,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(useStore).addressDialogShow) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          ref_key: "addrRef",
          ref: addrRef,
          model: addressData,
          rules: rules,
          "status-icon": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: "收件人名称",
              prop: "name",
              "label-width": "100px"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: addressData.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((addressData.name) = $event)),
                  placeholder: "请输入名称"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "手机号码",
              prop: "phone",
              "label-width": "100px"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: addressData.phone,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((addressData.phone) = $event)),
                  placeholder: "输入手机号码"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "邮编",
              prop: "postCode",
              "label-width": "100px"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: addressData.postCode,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((addressData.postCode) = $event)),
                  placeholder: "输入邮政编码"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "选择地址",
              prop: "selectAddr",
              "label-width": "100px"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_cascader, {
                  style: {"width":"100%"},
                  modelValue: addressData.selectAddr,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((addressData.selectAddr) = $event)),
                  placeholder: "请选择地址",
                  onChange: selectChange,
                  props: _unref(props),
                  options: options
                }, null, 8, ["modelValue", "props", "options"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "详细地址",
              prop: "detailAddress",
              "label-width": "100px"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  type: "textarea",
                  modelValue: addressData.detailAddress,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((addressData.detailAddress) = $event)),
                  placeholder: "输入详细地址"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules"]),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_el_button, { onClick: dialogCancelBtn }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [
              _createTextVNode("取 消")
            ])),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (submit(_unref(addrRef))))
          }, {
            default: _withCtx(() => _cache[8] || (_cache[8] = [
              _createTextVNode("确 定")
            ])),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["title", "modelValue"])
  ]))
}
}

})