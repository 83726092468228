import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "my-header" }
const _hoisted_2 = { style: {"display":"flex"} }
const _hoisted_3 = { class: "my-header" }
const _hoisted_4 = { style: {"display":"flex"} }
const _hoisted_5 = {
  class: "dialog-footer",
  style: {"display":"flex","justify-content":"space-around"}
}
const _hoisted_6 = {
  class: "dialog-footer",
  style: {"display":"flex","justify-content":"space-around"}
}

import {unBindAttrrelation_req} from '@/axiosAPI/product/attr.js'
import {
	attrGroupIdsearchreq,
	getAllOfattrGroupAndAttrRelation_req,
	getUnbindAttrList
} from '@/axiosAPI/product/attrgroup.js'
import {ref, reactive, computed} from 'vue'
import {AttrAndAttrGroupRelationBind_req} from "@/axiosAPI/product/attrAttrRelation.js";
//***************外部弹窗数据**********************//
interface AttrRelation {
	ids: number,
	attrId: number,
	attrName: string,
	valueSelect: string
}

export default /*@__PURE__*/_defineComponent({
  ...{
	name: "attrGroupRelationBind",
	inheritAttrs: false
},
  __name: 'attrGroupRelationBind',
  props: ['raltionshipdialogVisible'],
  emits: ['bindrelation_cancel']['refreshArrBindSmallWindow'],
  setup(__props, { expose: __expose, emit: __emit }) {

let miultDelete = ref([
	{
		attrId: 0,
		attrGroupId: 0
	}
])
let params = {
	id: 0,
	attrId: 0,
	attrGroupId: 0,
	catelogId: 0,
	type: '',
	pageSize: 10,
	currentPage: 1,
	title: '',
	//排序字段
	sidx: '',
	//排序方式
	order: 'asc',
	key: ''
	//关键字
}

//外部弹窗删除请求参数
let deleteParams = reactive([{
	attrId: 0,
	attrGroupId: 0,
}])
let currentPage = ref(1)
let pageSize = ref(5)
let total = ref(0);
let search = ref('')

const innerVisible = ref(false)
//内部小窗口分页
let innercurrentPage = ref(1)
let innerpageSize = ref(5)
let innertotal = ref(0)
//分组关系绑定参数对象
let bindRelationParams = {
	attrId:0,
	attrGroupId:0,
}

//小窗口关系绑定
async	function bindrelationBtn (index,row){
	bindRelationParams.attrId = row.attrId;
	bindRelationParams.attrGroupId = params.attrGroupId;

	await AttrAndAttrGroupRelationBind_req(bindRelationParams).then(()=>{
		attrAttrGroupUnbindList(innerparams);
	});
}
//*************内部弹窗数据***************//
//未绑定的属性列表
let innerAttrTableData = ref([{

}])
//查询未关联属性的关键字
let innersearchattrBindkey = ref('')
//未关联属性查询按钮
const innerattrbindable = () => {}
//未关联属性多选事件
const innerSelectionChangeofUnbindAttr = (data) =>{
	bindRelationParams.attrId = params.attrGroupId
	data.map((item)=>
			 bindRelationParams.attrGroupId = item.attrId
		)
	console.log(bindRelationParams)
}
//内部弹窗参数请求数据
let innerparams = {
	attrGroupId:0,
	currentPage:1,
	pageSize:5,
	sidx:0,//排序字段
	order:'',//排序方式
	key:''//关键字
}
//请求数据参数模板
//外部弹窗分页
const handleSizeChange = (V) => {
	params.pageSize = V
	orderbind(params)
}
//外部弹窗分页
const handleCurrentChange = (V) => {
	params.currentPage = V
	orderbind(params)
}
//属性分组解绑按钮
const handleunbindBtn = async (index, row) => {
	let res = {
		attrId: 0,
		attrGroupId: 0
	}
	res.attrId = row.attrId
	res.attrGroupId = params.attrGroupId
	Object.assign(deleteParams, res)
	await unBindAttrrelation_req(deleteParams).then(()=>{
		$emits('refreshArrBindSmallWindow')
		orderbind(params)
	});
}
//内部弹窗分页
const innerhandleSizeChange = (val) =>{
	innerparams.pageSize =  val
	attrAttrGroupUnbindList(innerparams)
}
//内部弹窗分页
const innerhandleCurrentChange = (val) =>{
	innerparams.currentPage = val
	attrAttrGroupUnbindList(innerparams)
}

//取消按钮提交定义
const $emits = __emit
//取消按钮
const bindrelation_cancel = () => {
	$emits('bindrelation_cancel')
	tableData.value = []

}
//弹出框三级下拉菜单数据动态响应数据
const attrGroupCatelogDate = reactive({
	categores: [],
	catelogPath: []
})

//弹出框三级下拉菜单显示属性设置
let props = reactive({
	//要发送的字段
	value: 'id',
	label: 'name',
	//子集合
	children: 'childer'
}) //参数不能定义为数组，否则不显示，只能定义对象

//属性分组绑定按钮（新建关联按钮）
const addrelationship =  () => {
	innerVisible.value = true
	//关键字搜索
	innerparams.key = innersearchattrBindkey.value
	//点击父窗口关联按钮时将分组id传给子组件，子组件解绑和关联属性都会用到这个值。
	innerparams.attrGroupId = params.attrGroupId

	attrAttrGroupUnbindList(innerparams)
}

/**
 * 获取属性和属性分组未关关系列表
 * @param data
 */
async function attrAttrGroupUnbindList(data){
	let res = await getUnbindAttrList(data);
	innerAttrTableData.value = res.data.data
	innertotal.value = res.data.total
}

//外部窗口确定按钮
const bindrelation_submit = () => {
	$emits('bindrelation_cancel')
	tableData.value = []
}

//属性分组批量删除按钮
const bacheDelete_Btn = async () => {
	console.log("deletapar",deleteParams)
	await unBindAttrrelation_req(deleteParams);
}
//属性分组批量删除多选事件
const handleSelectionChange = (Val) => {
	let res = {
		attrIds:[],
		attrGroupId:params.attrGroupId
	}
	 Val.map(item=>{
	 return res.attrIds.push(item.attrId)
	} )
	console.log("res",res)

}

//内部dialog取消按钮
const innerDialog_cancel = () => {
	innerVisible.value = false
	getAllOfattrGroupAndAttrRelation()
}
//内部dialog确定按钮
const innerDialog_submit = () => {
	innerVisible.value = false
	getAllOfattrGroupAndAttrRelation()
}
const attrGroupBindtableData = reactive({})
// 小窗口过滤输入框功能
let tableData = ref([])


/**
 * 父组件关系绑定请求
 * @param param
 */
const orderbind = (param) => {
	params = param
	getAllOfattrGroupAndAttrRelation()
}

//获取所有属性和属性分组绑定关系列表
async function getAllOfattrGroupAndAttrRelation(){
	let res = await getAllOfattrGroupAndAttrRelation_req(params);
	tableData.value = res.data.data
}


//向父元素暴露函数
__expose({orderbind})


return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    "model-value": __props.raltionshipdialogVisible,
    title: "属性分组绑定窗口",
    "show-close": false
  }, {
    header: _withCtx(({ close, titleId, titleClass }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_button, {
            onClick: addrelationship,
            type: "success",
            style: {"align-items":"center","display":"flex"}
          }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [
              _createTextVNode(" 新建关系 ")
            ])),
            _: 1
          }),
          _createVNode(_component_el_button, {
            onClick: bacheDelete_Btn,
            type: "danger"
          }, {
            default: _withCtx(() => _cache[8] || (_cache[8] = [
              _createTextVNode(" 批量删除 ")
            ])),
            _: 1
          })
        ]),
        _cache[9] || (_cache[9] = _createElementVNode("h4", null, "属性分组绑定窗口", -1)),
        _cache[10] || (_cache[10] = _createElementVNode("div", null, null, -1))
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_el_button, {
          type: "danger",
          onClick: bindrelation_cancel
        }, {
          default: _withCtx(() => _cache[18] || (_cache[18] = [
            _createTextVNode("取消")
          ])),
          _: 1
        }),
        _createElementVNode("span", null, [
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: bindrelation_submit
          }, {
            default: _withCtx(() => _cache[19] || (_cache[19] = [
              _createTextVNode("确定")
            ])),
            _: 1
          })
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("template", null, [
        _createVNode(_component_el_dialog, {
          modelValue: innerVisible.value,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((innerVisible).value = $event)),
          width: "30%",
          center: "",
          "append-to-body": "",
          "show-close": false
        }, {
          header: _withCtx(({ close, titleId, titleClass }) => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_el_input, {
                  modelValue: _unref(innersearchattrBindkey),
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(innersearchattrBindkey) ? (innersearchattrBindkey).value = $event : innersearchattrBindkey = $event)),
                  placeholder: "请输入可选值查询"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_el_button, {
                  onClick: innerattrbindable,
                  type: "success",
                  style: {"align-items":"center","display":"flex"}
                }, {
                  default: _withCtx(() => _cache[11] || (_cache[11] = [
                    _createTextVNode(" 查询 ")
                  ])),
                  _: 1
                })
              ]),
              _cache[12] || (_cache[12] = _createElementVNode("div", null, "属性绑定", -1)),
              _cache[13] || (_cache[13] = _createElementVNode("div", null, null, -1))
            ])
          ]),
          footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_el_button, {
                type: "danger",
                onClick: innerDialog_cancel
              }, {
                default: _withCtx(() => _cache[15] || (_cache[15] = [
                  _createTextVNode("取消")
                ])),
                _: 1
              }),
              _createElementVNode("span", null, [
                _createVNode(_component_el_button, {
                  type: "primary",
                  onClick: innerDialog_submit
                }, {
                  default: _withCtx(() => _cache[16] || (_cache[16] = [
                    _createTextVNode("确认")
                  ])),
                  _: 1
                })
              ])
            ])
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_el_table, {
                data: _unref(innerAttrTableData),
                style: {"width":"100%"},
                onSelectionChange: innerSelectionChangeofUnbindAttr
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select),
                  _createVNode(_component_el_table_column, { type: "selection" }),
                  _createVNode(_component_el_table_column, {
                    label: "属性id",
                    prop: "attrId"
                  }),
                  _createVNode(_component_el_table_column, {
                    label: "属性名",
                    prop: "attrName"
                  }),
                  _createVNode(_component_el_table_column, {
                    label: "可选值",
                    prop: "valueSelect"
                  }),
                  _createVNode(_component_el_table_column, {
                    label: "操作",
                    align: "center",
                    size: "small"
                  }, {
                    default: _withCtx((scope) => [
                      _createVNode(_component_el_button, {
                        type: "success",
                        onClick: ($event: any) => (bindrelationBtn(scope.$index, scope.row))
                      }, {
                        default: _withCtx(() => _cache[14] || (_cache[14] = [
                          _createTextVNode("绑定")
                        ])),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["data"]),
              _createVNode(_component_el_pagination, {
                "current-page": _unref(innercurrentPage),
                "onUpdate:currentPage": _cache[1] || (_cache[1] = ($event: any) => (_isRef(innercurrentPage) ? (innercurrentPage).value = $event : innercurrentPage = $event)),
                "page-size": _unref(innerpageSize),
                "onUpdate:pageSize": _cache[2] || (_cache[2] = ($event: any) => (_isRef(innerpageSize) ? (innerpageSize).value = $event : innerpageSize = $event)),
                "page-sizes": [5, 10, 20, 30],
                layout: " prev, pager, next, jumper,->,total, sizes,",
                total: _unref(innertotal),
                onSizeChange: innerhandleSizeChange,
                onCurrentChange: innerhandleCurrentChange
              }, null, 8, ["current-page", "page-size", "total"])
            ])
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_el_table, {
          data: _unref(tableData),
          style: {"width":"100%"},
          onSelectionChange: handleSelectionChange
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_select),
            _createVNode(_component_el_table_column, { type: "selection" }),
            _createVNode(_component_el_table_column, {
              label: "属性id",
              prop: "attrId"
            }),
            _createVNode(_component_el_table_column, {
              label: "属性名",
              prop: "attrName"
            }),
            _createVNode(_component_el_table_column, {
              label: "可选值",
              prop: "valueSelect"
            }),
            _createVNode(_component_el_table_column, { align: "right" }, {
              header: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _unref(search),
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_isRef(search) ? (search).value = $event : search = $event)),
                  size: "small",
                  placeholder: "Type to search"
                }, null, 8, ["modelValue"])
              ]),
              default: _withCtx((scope) => [
                _createVNode(_component_el_button, {
                  size: "small",
                  type: "danger",
                  onClick: ($event: any) => (handleunbindBtn(scope.$index, scope.row))
                }, {
                  default: _withCtx(() => _cache[17] || (_cache[17] = [
                    _createTextVNode("解除关联")
                  ])),
                  _: 2
                }, 1032, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["data"]),
        _createVNode(_component_el_pagination, {
          "current-page": _unref(currentPage),
          "onUpdate:currentPage": _cache[5] || (_cache[5] = ($event: any) => (_isRef(currentPage) ? (currentPage).value = $event : currentPage = $event)),
          "page-size": _unref(pageSize),
          "onUpdate:pageSize": _cache[6] || (_cache[6] = ($event: any) => (_isRef(pageSize) ? (pageSize).value = $event : pageSize = $event)),
          "page-sizes": [5, 10, 20, 30],
          layout: " prev, pager, next, jumper,->,total, sizes,",
          total: _unref(total),
          onSizeChange: handleSizeChange,
          onCurrentChange: handleCurrentChange
        }, null, 8, ["current-page", "page-size", "total"])
      ])
    ]),
    _: 1
  }, 8, ["model-value"]))
}
}

})